import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getModelosUsuario } from "../Control/Firestore";
import "../estilos/panel.css";
import ReusableChart, { barData, lineData, pieData, doughnutData } from "./Charts";

const Modelos = () => {
    const [models, setModels] = useState([]);  // Estado para los modelos del usuario
    const [user, setUser] = useState(null);  // Estado para el usuario autenticado
    const auth = getAuth();
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);

    const handleUserClick = (index, model) => {
        setActiveIndex(index === activeIndex ? null : index);  // Cambiar el índice de la tarjeta seleccionada
        setSelectedModel(model);
    };

    useEffect(() => {
        // Verificar si el usuario está autenticado
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);

                // Obtener los modelos del usuario autenticado utilizando su uid
                try {
                    const modelosData = await getModelosUsuario(currentUser.uid);
                    setModels(modelosData);  // Aquí cambiamos a setModels
                } catch (error) {
                    console.error("Error obteniendo los modelos del usuario:", error);
                }
            } else {
                setUser(null);
                setModels([]);  // Aquí también cambiamos a setModels
            }
        });

        // Limpiar la suscripción cuando el componente se desmonta
        return () => unsubscribe();
    }, [auth]);


    const renderCharts = (configuraciones) => {
        return configuraciones.map((config, index) => {
            let chartType = config === "Bar" ? "bar" :
                config === "Line" ? "line" :
                    config === "Pie" ? "pie" :
                        config === "Doughnut" ? "doughnut" : null;

            const data = chartType === "bar" ? barData :
                chartType === "line" ? lineData :
                    chartType === "pie" ? pieData :
                        chartType === "doughnut" ? doughnutData : null;

            return (
                <div key={index} className="chart-container">
                    <ReusableChart type={chartType} data={data} />
                </div>
            );
        });
    };

    return (
        <div className="dashboard">
            <div className="sidebar">
                <h3>Modelos</h3>
                <input type="text" placeholder="Buscar" className="search" />

                {/* Mapeamos los modelos obtenidos desde Firestore */}
                {models.map((model, index) => (
                    <div
                        key={index}
                        className={`user-card ${activeIndex === index ? "active" : ""}`}
                        onClick={() => handleUserClick(index, model)}  // Aquí usamos el índice para rastrear la tarjeta seleccionada
                    >
                        <div className="user-name">{model.Modelo || "Sin nombre"}</div>
                        <div className="user-role">{model.Tipo || "Sin tipo"}</div>
                        <div className="user-balance">{model.Plan || "Sin estado"}</div>
                    </div>
                ))}
            </div>

            <div className="content">
                {selectedModel ? (
                    <div>
                        <h2>{`Dashboard de ${selectedModel.Modelo}`}</h2>
                        <div className="kpis-container">
                            <div className="kpi-card">
                                <h3>Rendimiento</h3>
                                <div className="kpi-content">
                                    <div className="kpi-value">{selectedModel.Rendimiento || "No disponible"}</div>
                                </div>
                            </div>

                            <div className="kpi-card">
                                <h3>Consumo</h3>
                                <div className="kpi-content">
                                    <div className="kpi-value">{selectedModel.Consumo || "No disponible"}</div>
                                </div>
                            </div>

                            <div className="kpi-card">
                                <h3>Solicitudes</h3>
                                <div className="kpi-content">
                                    <div className="kpi-value">{selectedModel.Solicitudes || "No disponible"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="contenedor-dashboard">
                            {/* Renderizamos las gráficas basadas en las configuraciones del modelo */}
                            <div className="kpis-container">
                            <div className="kpi-card-dash">
                            <h3>{selectedModel.Titulo && selectedModel.Titulo[0] ? selectedModel.Titulo[0] : "No disponible"}</h3>
                                <div className="kpi-content">
                                    <div className="kpi-value">{selectedModel.Rendimiento || "No disponible"}</div>
                                </div>
                            </div>

                            <div className="kpi-card-dash">
                            <h3>{selectedModel.Titulo && selectedModel.Titulo[1] ? selectedModel.Titulo[1] : "No disponible"}</h3>
                                <div className="kpi-content">
                                    <div className="kpi-value">{selectedModel.Consumo || "No disponible"}</div>
                                </div>
                            </div>

                            <div className="kpi-card-dash">
                            <h3>{selectedModel.Titulo && selectedModel.Titulo[2] ? selectedModel.Titulo[2] : "No disponible"}</h3>
                                <div className="kpi-content">
                                    <div className="kpi-value">{selectedModel.Solicitudes || "No disponible"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="kpis-container-graphics">
                        {renderCharts(selectedModel.Configuraciones || [])}
                        </div>
                        </div>
                    </div>
                ) : (
                    <h2>Selecciona un modelo para ver su dashboard</h2>
                )}
            </div>
        </div>
    );
};

export default Modelos;
