import React, { useState, useEffect } from "react";
import { getCertificadoById } from "../Control/CertiFire"; // Tus funciones de consulta a Firebase
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import "../estilos/detalles.css";
import "../estilos/greek.css";
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { signInWithGoogle, checkAuthStatus, logout } from '../Control/Auth';

// Hook para obtener el query parameter de la URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Detalles = () => {
  const query = useQuery();
  const id = query.get("id"); // Obtener el id del query parameter de la URL
  const [modulo, setModulo] = useState(null); // Para los módulos
  const [clases, setClases] = useState([]); // Para las clases
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Guardamos la información del usuario
      } else {
        setUser(null); // Si no está autenticado, ponemos null
      }
    });

    // Limpiar la suscripción cuando el componente se desmonta
    return () => unsubscribe();
  }, [auth]);

  const handlePayment = () => {
    if (user) {
      // Si el usuario está autenticado, procede con el pago
      console.log("Usuario autenticado. Procede con el pago.");
      // Aquí deberías manejar el flujo del pago (por ejemplo, redirigir a una página de pago o abrir un popup)
    } else {
      // Si el usuario no está autenticado, redirige a la página de autenticación
      console.log("Usuario no autenticado. Redirigiendo a iniciar sesión.");
      signInWithGoogle(); // Llama al flujo de autenticación o redirige
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          // Llamar a la función para obtener los módulos y clases
          const { certificado, clases } = await getCertificadoById(id); // Asegúrate de que la función regresa el campo "Nombre" y "Aprendizajes"
          setModulo(certificado);
          setClases(clases); // Aquí guardamos las clases
        } else {
          throw new Error("No se proporcionó un ID en la URL");
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setError(error.message);
      }
    };

    fetchData();
  }, [id]);

  // Función para manejar el click en cada elemento
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Alternar el estado activo
  };

  if (!modulo) {
    // Si el módulo no ha sido cargado aún, mostramos un mensaje de carga o un valor por defecto
    return <p>Cargando datos del módulo...</p>;
  }

  return (
    <div>
      <Helmet>
        <title>{modulo?.data?.Nombre || "Certificación"} | Grupo Greek</title>
        <meta name="description" content="Detalles del certificado o módulo seleccionado" />
      </Helmet>

      {/* Encabezado */}
      <div className="inner-header flex">
        <h1 className="title-wave">Certificaciones</h1>
      </div>

      {/* Ondas de diseño */}
      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7)" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>

      {/* Información de compra */}

      <div className="card-compra">
          <div className="header-compra">
            <span className="title-compra">Comprar ahora</span>
            <span className="price-compra">${modulo?.data?.Precio || "Cargando..."}</span>
          </div>
          <p className="desc">
            Esto te dará acceso ilimitado al curso que estás seleccionando, además de tu examen de certificación.
          </p>
          <ul className="lists">
            <li className="list">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Pago en línea</span>
            </li>
            <li className="list">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Transferencia</span>
            </li>
            <li className="list">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Pago seguro</span>
            </li>
          </ul>
          <button type="button" className="action" onClick={handlePayment}>Pagar</button>
        </div>

      {/* Contenedor principal */}
      <div className="main-container">

        {/* Información del módulo (certificado) */}
        <div className="main-aprendizaje">
          <div className="card-aprendizaje">
            <div className="heading-aprendizaje">¿Qué aprenderás?</div>
            {modulo?.data ? (
              <div className="details">
                <ul className="aprendizaje-lista">
                  {modulo.data.Aprendizajes && modulo.data.Aprendizajes.length > 0 ? (
                    modulo.data.Aprendizajes.map((aprendizaje, index) => (
                      <li key={index} className="listaAprendizaje">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="svg-icon">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>{aprendizaje}</li>
                    ))
                  ) : (
                    <li>No hay aprendizajes disponibles.</li>
                  )}
                </ul>
              </div>
            ) : (
              <p>Cargando datos del módulo...</p>
            )}
          </div>
        </div>

        {/* Acordeón para mostrar las clases */}
        <div className="accordion">
          {clases.length > 0 ? (
            clases.map((clase, index) => (
              <div key={clase.id} className={`accordion-item ${activeIndex === index ? "open" : ""}`}>
                <div className="link" onClick={() => toggleAccordion(index)}>
                  <h3 className="accordion-titulo">{clase.id}</h3>
                  <i className={`fa fa-chevron-down ${activeIndex === index ? "rotate" : ""}`}></i>
                </div>

                <div className={`submenu ${activeIndex === index ? "show" : ""}`}>
                  <p className="accordion-clases">Duración: {clase.data.Duracion} horas</p>

                  {/* Sección del temario */}
                  {clase.data.Temario && clase.data.Temario.length > 0 ? (
                    <ul className="temario-list">
                      {clase.data.Temario.map((tema, temaIndex) => (
                        <li key={temaIndex} className="tema-item">
                          {tema}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No hay temario disponible.</p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No hay clases disponibles</p>
          )}
        </div>

        {/* Requisitos (puedes modificar según tus datos) */}
        <div className="main-descripcion-contenido">
  <div className="card-descripcion-contenido">
    <div className="heading-descripcion-contenido">Descripcion</div>
    {modulo?.data ? (
      <div className="details-descripcion">
        <div dangerouslySetInnerHTML={{ __html: modulo.data.Descripcion }}></div>
      </div>
    ) : (
      <p>Cargando datos del módulo...</p>
    )}

    <div className="heading-descripcion-contenido">Requisitos</div>
    {modulo?.data?.Requisitos ? (
      <ul className="temario-list">
        {modulo.data.Requisitos.map((requisito, index) => (
          <li key={index} className="requisito-item">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="svg-icon">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
            {requisito}
          </li>
        ))}
      </ul>
    ) : (
      <p>No hay requisitos disponibles.</p>
    )}
  </div>
</div>
      </div>

      <Footer />
    </div>
  );
};

export default Detalles;
