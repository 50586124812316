import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { getCertificados } from "../Control/CertiFire";
import "../estilos/greek.css";
import { Helmet } from "react-helmet";
import Footer from "../component/Footer";
import '../estilos/marketplace.css'
import { Link, useNavigate } from "react-router-dom";
import { signInWithGoogle, checkAuthStatus, logout } from '../Control/Auth';



const Modelos = () => {
  const [certificados, setCertificados] = useState([]);
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  const db = getFirestore();
  const descripcionServicio = "Modelos en renta en Grupo Greek";

  useEffect(() => {
    // Verificar si el usuario está autenticado
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser); // Guardamos la información del usuario
      } else {
        setUser(null); // Si no está autenticado, ponemos null
      }
    });

    // Limpiar la suscripción cuando el componente se desmonta
    return () => unsubscribe();
  }, [auth]);

  const handlePayment = () => {
    if (user) {
      // Si el usuario está autenticado, procede con el pago
      console.log("Usuario autenticado. Procede con el pago.");
      // Aquí deberías manejar el flujo del pago (por ejemplo, redirigir a una página de pago o abrir un popup)
    } else {
      // Si el usuario no está autenticado, redirige a la página de autenticación
      console.log("Usuario no autenticado. Redirigiendo a iniciar sesión.");
      signInWithGoogle(); // Llama al flujo de autenticación o redirige
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const certificadosData = await getCertificados();
        setCertificados(certificadosData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleNavigate = (id) => {
    window.location.href = `/Detalles?id=${id}`;
  }

  const handlePay = () => {
    window.location.href = "/Pago";
  }

  return (
    <>
      <Helmet>
        <title>Modelos del equipo Greek | Grupo Greek</title>
        <meta name="description" content={descripcionServicio} />
      </Helmet>
      <div className="inner-header flex">
        <h1 className='title-wave'>Certificaciones de Grupo Greek</h1>
      </div>
      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>
      <div>
        <h1 className="Titulos">Certificaciones</h1>
        <div className="main-modelos">
          {certificados.map((doc, index) => (
            <React.Fragment key={index}>
              <div className="card-modelos">
                <div className="heading">{doc.data.Nombre}</div>
                <div className="price">${doc.data.Precio}</div>
                <button className="btn1" onClick={handlePayment}>Comprar</button>
                <button className="btn2" onClick={() => handleNavigate(doc.id)}>Detalles</button>
              </div>
              <div className="glasses">
                <img
                  id="image0"
                  width="100"
                  height="100"
                  x="90"
                  y="-10"
                  src={doc.data.Imagen}
                  alt="Data Science"
                />
              </div>
            </React.Fragment>
          ))}
          <div className="containervermas">
            <button className="VerMas">
              Ver más
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Modelos;
