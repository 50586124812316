import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  limit,
  query,
  doc,
  getDoc, // Este es necesario para obtener documentos por ID
} from "firebase/firestore";
import config from "../Connect/firebase-config";

// Inicialización de Firebase
const app = initializeApp(config);
const db = getFirestore(app);

// Función para obtener una lista de certificados con un límite de 5
export const getCertificados = async () => {
  try {
    const collectionRef = collection(db, "Certificaciones"); // Nombres de colección consistentes
    const q = query(collectionRef, limit(4)); // Limitamos la consulta a 5 certificados
    const certificadosSnapshot = await getDocs(q);
    return certificadosSnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching certificados:", error);
    throw error;
  }
};

export const getCertificadoById = async (id) => {
  try {
    // Referencia al documento del certificado específico por ID
    const docRef = doc(db, "Certificaciones", id); // Cambia "Certificaciones" por el nombre correcto de tu colección
    const certificadoSnapshot = await getDoc(docRef); // Obtiene el documento del certificado

    if (certificadoSnapshot.exists()) {
      const certificadoData = certificadoSnapshot.data(); // Datos del certificado

      // Obtener las clases asociadas a este certificado desde la subcolección "Clases"
      const clasesRef = collection(docRef, "Clases"); // Subcolección dentro del certificado
      const clasesSnapshot = await getDocs(clasesRef); // Obtiene todas las clases de la subcolección "Clases"
      
      // Mapeamos las clases obtenidas en un arreglo de objetos
      const clases = clasesSnapshot.docs.map((claseDoc) => ({
        id: claseDoc.id,
        data: claseDoc.data(), // Aquí obtienes contenido, duración, etc.
      }));

      // Retornar los datos del certificado y las clases
      return {
        certificado: { id: certificadoSnapshot.id, data: certificadoData }, // Información del certificado
        clases, // Clases del certificado
      };
    } else {
      throw new Error("Certificado no encontrado");
    }
  } catch (error) {
    console.error("Error al obtener el certificado:", error);
    throw error;
  }
};
