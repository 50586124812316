import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  onSnapshot,
  getDocs,
  addDoc,
  doc,
  setDoc,
  getDoc, // Asegúrate de importar getDoc
  query,
  where,
} from "firebase/firestore";
import config from "../Connect/firebase-config";

const app = initializeApp(config);
const db = getFirestore(app);

export const getEquipo = async () => {
  try {
    const collectionRef = collection(db, "Equipo");
    const documents = await getDocs(collectionRef);
    return documents;
  } catch (error) {
    console.error("Error fetching equipo:", error);
    throw error;
  }
};

export const getServicios = async () => {
  try {
    const collectionRef = collection(db, "Servicios");
    const servicios = await getDocs(collectionRef);
    return servicios;
  } catch (error) {
    console.error("Error fetching equipo:", error);
    throw error;
  }
};

export const Servicios = async () => {
  try {
    const collectionRef = collection(db, "Servicios");
    const serviciosSnapshot = await getDocs(collectionRef);
    const serviciosData = serviciosSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return serviciosData;
  } catch (error) {
    console.error("Error obteniendo servicios:", error);
    throw error;
  }
};

export const setDataContact = async (contactData) => {
  try {
    const collectionRef = collection(db, "Citas");
    const newReservaRef = await addDoc(collectionRef, contactData);
    return true; // Indicar que la reserva fue exitosa
  } catch (error) {
    console.error(error);
    return false; // Indicar que hubo un error en la reserva
  }
};

// Función para almacenar los datos del usuario solo si es necesario
export const setUserData = async (userData) => {
  try {
    // Referencia al documento del usuario por su UID
    const userRef = doc(db, "Usuarios", userData.uid);

    // Obtener los datos actuales del usuario en Firestore
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const currentUserData = userSnapshot.data();

      // Verificar si los datos han cambiado
      if (
        currentUserData.name !== userData.name ||
        currentUserData.email !== userData.email ||
        currentUserData.photoURL !== userData.photoURL ||
        currentUserData.emailVerified !== userData.emailVerified
      ) {
        // Si los datos han cambiado, actualizarlos en Firestore
        await setDoc(userRef, userData, { merge: true });
        console.log("Datos del usuario actualizados en Firestore.");
      } else {
        console.log("No hay cambios en los datos del usuario.");
      }
    } else {
      // Si el usuario no existe en Firestore, creamos el documento
      await setDoc(userRef, userData);
      console.log("Datos del usuario almacenados en Firestore.");
    }
  } catch (error) {
    console.error("Error al guardar los datos del usuario:", error);
  }
};

export const getModelosUsuario = async (userId) => {
  try {
    // Referencia a la colección de usuarios
    const usuariosRef = collection(db, "Usuarios");
    
    // Crear una consulta donde el campo 'uid' sea igual al 'userId' (el uid del usuario autenticado)
    const q = query(usuariosRef, where("uid", "==", userId));
    
    // Ejecutar la consulta
    const querySnapshot = await getDocs(q);
    
    // Verificar si encontramos un documento para el usuario
    if (querySnapshot.empty) {
      console.error("No se encontró un documento para el usuario");
      return [];
    }

    // Asumimos que solo habrá un documento que coincida con el uid
    const userDoc = querySnapshot.docs[0];

    // Obtener la referencia de la subcolección 'Modelos'
    const modelosRef = collection(userDoc.ref, "Modelos");
    
    // Obtener todos los documentos de la subcolección
    const modelosSnapshot = await getDocs(modelosRef);
    
    // Extraer los datos de los modelos
    const modelosData = modelosSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return modelosData;
  } catch (error) {
    console.error("Error al obtener los modelos del usuario:", error);
    throw error;
  }
};