import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import "../estilos/greek.css";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Principal = () => {
  const db = getFirestore();

  const descripcionServicio =
    "Grupo Greek ofrece servicios de desarrollo de software personalizados para empresas de todos los tamaños. Nuestros desarrolladores expertos crean soluciones de software escalables y de alto rendimiento que satisfacen sus necesidades específicas. Contrate a nuestro equipo hoy mismo y obtenga una cotización gratuita.";

  return (
    <div>
      <Helmet>
        <title>
          Desarrollo de software personalizado para empresas | Grupo Greek
        </title>
        <meta name="description" content={descripcionServicio} />
      </Helmet>
      <div class="e-card playing">

        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>

        <div class="infotop">
          Desarrollo de software
          <div class="name">
            En el mundo de la innovación, nos destacamos como pioneros al
            desarrollar tecnología que está a tu alcance. Enfocados en hacer
            avances tecnológicos accesibles para todos, desde la concepción
            hasta la implementación, nos esforzamos por brindar soluciones
            personalizadas y poderosas. Cada proyecto es una oportunidad para
            demostrar nuestro compromiso con la excelencia, haciendo que la
            tecnología trabaje para ti de una manera que se adapte perfectamente
            a tus necesidades.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Principal;
