import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import firebaseConfig from '../Connect/firebase-config'; // Importamos la configuración desde tu archivo separado
import { setUserData } from "./Firestore";

// Inicializa la app de Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Proveedor de autenticación de Google
const googleProvider = new GoogleAuthProvider();

// Función para iniciar sesión con Google
export const signInWithGoogle = () => {
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      const user = result.user;
      
      // Extraer la información del usuario
      const userData = {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
      };

      // Almacenar la información del usuario en Firestore
      setUserData(userData);
      console.log("Usuario autenticado y datos almacenados en Firestore.");
    })
    .catch((error) => {
      if (error.code === 'auth/popup-closed-by-user') {
        console.log("El usuario cerró la ventana emergente.");
      } else {
        console.error("Error al iniciar sesión:", error.message);
      }
    });
};

// Función para cerrar sesión
export const logout = async () => {
  try {
    await signOut(auth);
    console.log("Sesión cerrada correctamente");
  } catch (error) {
    console.error("Error al cerrar sesión:", error);
  }
};

// Función para verificar si el usuario está autenticado
export const checkAuthStatus = (callback) => {
  // Firebase detecta automáticamente el estado de autenticación
  auth.onAuthStateChanged((user) => {
    if (user) {
      // Usuario está autenticado
      console.log("Usuario autenticado:", user);
      callback(user);
    } else {
      // Usuario no autenticado
      console.log("No hay usuario autenticado");
      callback(null);
    }
  });
};

