import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { Servicios, setDataContact } from "../Control/Firestore";
import { useLocation } from "react-router-dom";
import '../estilos/aviso.css';

const Aviso = () => {

  return (
    <div>
      <div className="contenedor-aviso">
    <h1 className="title-aviso">Aviso de privacidad</h1>
    <p>
        De acuerdo con lo establecido, los datos personales contenidos en el presente se encuentran protegidos por la Ley Federal de Protección de Datos Personales en Posesión de Particulares y serán tratados por <strong>Grupo Greek</strong>, con domicilio en Acapulco de Juárez, Guerrero, en los términos de nuestro Aviso de Privacidad. 
    </p>
    <p>
        <strong>Grupo Greek</strong> se compromete a mantener la estricta confidencialidad de sus datos personales, así como a llevar a cabo esfuerzos razonables para su protección. Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, necesarias para dar seguimiento a sus proyectos:
    </p>
    <ul>
        <li>Nombre</li>
        <li>Edad</li>
        <li>Sexo</li>
        <li>Teléfono fijo y/o celular</li>
        <li>Correo electrónico</li>
        <li>Red Social de contacto</li>
        <li>Dirección</li>
        <li>Experiencia Laboral</li>
    </ul>
    <p>
        Sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distinta a nosotros, y serán utilizados exclusivamente para los fines señalados. Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones de su uso (Acceso). 
    </p>
    <p>
        También tiene derecho a solicitar la corrección de su información personal si está desactualizada, es inexacta o incompleta (Rectificación); a que su información se elimine de nuestros registros o bases de datos si considera que no está siendo utilizada adecuadamente (Cancelación); y a oponerse al uso de sus datos para fines específicos (Oposición). Estos derechos se conocen como derechos <strong>ARCO</strong>.
    </p>
    <p>
        <strong>Grupo Greek</strong> ha adoptado los niveles de seguridad requeridos por la Ley y su Reglamento, bajo los principios de licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad, todo bajo estrictas reglas de seguridad y confidencialidad.
    </p>
    <p>
        Nos reservamos el derecho de modificar en cualquier momento el presente aviso de privacidad para cumplir con nuevas normativas, políticas internas, requerimientos de servicios, o prácticas del mercado. Cualquier modificación estará disponible a través de nuestro portal web, en la sección de "Aviso de Privacidad".
    </p>
    <p>
        Al navegar en el sitio web de <strong>Grupo Greek</strong>, confirma que ha leído, entendido y aceptado los términos expuestos anteriormente.
    </p>
</div>

      <Footer />
    </div>
  );
};

export default Aviso;
