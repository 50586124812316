import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import Navigation from './component/Navigation';
import Servicios from './component/Servicios';
import Greek from './component/Greek';
import Equipo from './component/Equipo';
import Contacto from './component/Contacto';
import Aviso from './component/aviso';
import Certificaciones from './component/certificaciones';
import Detalles from './component/Detalles';
import Pago from './component/pago';
import Perfil from './component/Perfil';
import Certificado from './component/Certificado';
import Models from './ModelFinance/Models';
import UserModels from './ModelFinance/UserModels';

import React from 'react';

function App() {

  return (
    <div className='App'>
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path='/' element={<Greek />} />
          <Route path='/Servicios' element={<Servicios />} />
          <Route path='/Equipo' element={<Equipo/>}/>
          <Route path='/Contacto' element={<Contacto/>}/>
          <Route path='/Aviso' element={<Aviso/>}/>
          <Route path='/Detalles' element={<Detalles/>} />
          <Route path='/Certificaciones' element={<Certificaciones/>} />
          <Route path='/Modelos' element={<Models/>} />
          <Route path='/Pago' element={<Pago/>} />
          <Route path='/Perfil' element={<Perfil/>}/>
          <Route path='/Certificado' element={<Certificado/>}/>
          <Route path='/Mis_modelos' element={<UserModels/>}/>
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
