import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import "../estilos/greek.css";
import emergentes from "../icons/alcance.png";
import AI from "../icons/inteligencia-artificial.png";
import BI from "../icons/negocios-inteligentes.png";
import Greek from "../icons/arquitectura-de-datos.gif";
import Presente from "../icons/presencia.png";
import Respuestas from "../icons/respuestas.png";
import Nuevas from "../icons/tecnologia.png";
import Medida from "../icons/medida.png";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Principal = () => {
  const db = getFirestore();

  const descripcionServicio =
    "Grupo Greek ofrece servicios de desarrollo de software personalizados para empresas de todos los tamaños. Nuestros desarrolladores expertos crean soluciones de software escalables y de alto rendimiento que satisfacen sus necesidades específicas. Contrate a nuestro equipo hoy mismo y obtenga una cotización gratuita.";

  return (
    <div>
      <Helmet>
        <title>
          Desarrollo de software personalizado para empresas | Grupo Greek
        </title>
        <meta name="description" content={descripcionServicio} />
      </Helmet>

      <div className="inner-header flex">
        <h1 className='title-wave'>Desarrollo de software</h1>
      </div>

      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>

      <div className="Titulos">Valor que te entregamos</div>

      <section className="container-valor">
        <div className="valor">
          <div className="valor-empresarial">
            <h1>Desarrollando tecnolog&iacute;a a tu alcance</h1>
            <img src={emergentes} className="IconValor"></img>
            <div className="text-valor">
              <h2>
                En nuestro compromiso con la accesibilidad tecnológica, nos
                destacamos por desarrollar soluciones de vanguardia que están al
                alcance de todos. Desde la concepción hasta la implementación,
                nuestro enfoque se centra en hacer que la tecnología sea más
                accesible, sin comprometer la calidad ni las capacidades
                avanzadas. Cada proyecto es una oportunidad para demostrar
                nuestro compromiso con la innovación.
              </h2>
            </div>
          </div>
          <div class="valor-empresarial">
            <h1>Inteligencia Artificial</h1>
            <img src={AI} className="IconValor"></img>
            <div className="text-valor">
              <h2>
                En el corazón de la revolución digital, nos destacamos al
                impulsar la inteligencia artificial a nuevas alturas. Nuestro
                compromiso con la innovación se refleja en soluciones de IA
                vanguardistas que transforman la manera en que interactúas con
                la tecnología. Desde algoritmos inteligentes hasta aplicaciones
                de aprendizaje automático, cada proyecto es una oportunidad para
                demostrar nuestra dedicación a la excelencia.
              </h2>
            </div>
          </div>
          <div class="valor-empresarial">
            <h1>Negocios inteligentes</h1>
            <img src={BI} className="IconValor"></img>
            <div className="text-valor">
              <h2>
                En nuestro enfoque hacia la creación de negocios inteligentes,
                nos destacamos al ofrecer estrategias basadas en datos. Desde la planificación hasta la
                implementación, actuamos como tu socio estratégico en el camino
                hacia el éxito sostenible. La inteligencia empresarial impulsa
                cada aspecto de nuestro servicio, guiando la transformación de
                tu empresa en la era digital. Convirtiendo los datos en
                respuestas.
              </h2>
            </div>
          </div>
        </div>
      </section>

      <div className="Titulos">Ventajas de contratarnos</div>

      <section className="ContainerVentajas">
        <div class="ventajas">
          <div className="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">A medida</h1>
            </div>
            <h2 className="textVentajas">
              Desarrollamos la solución que cubra tus necesidades. Con nuestros arquitectos y desarrolladores de
              software solucionaremos tus problemas y mejoraremos los procesos
              de tu empresa.
            </h2>
            <img src={Medida} className="VentajasImagen"></img>
          </div>
          <div className="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">Tecnologías</h1>
            </div>
            <h2 className="textVentajas">
              Lideramos la implementación de nuevas tecnologías para impulsar la
              innovación en tu empresa. Desde la planificación hasta la
              ejecución, ofrecemos soluciones que marcan el camino hacia la
              excelencia en la era digital.
            </h2>
            <img src={Nuevas} className="VentajasImagen"></img>
          </div>
          <div class="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">Respuestas</h1>
            </div>
            <h2 className="textVentajas">
              Nos destacamos en convertir datos en respuestas significativas.
              Nuestra experiencia en análisis de datos proporciona insights
              valiosos para informar decisiones estratégicas, guiando tu camino
              hacia el éxito informado.
            </h2>
            <img src={Respuestas} className="VentajasImagen"></img>
          </div>
          <div class="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">Presencia</h1>
            </div>
            <h2 className="textVentajas">
              Nos dedicamos a hacer que tus ideas perduren. Ya sea a través de
              desarrollo, diseño o implementación, aseguramos que tus conceptos
              estén siempre presentes, transformando visiones en realidades.
            </h2>
            <img src={Presente} className="VentajasImagen"></img>
          </div>
        </div>
      </section>

      <div className="Titulos">Nuestra metodologia de trabajo</div>

      <section class="metodologia-bento-row">
        <div class="bento-item-metodologia">
          <h1>Con la metodología correcta</h1>
          <h2>
            Alcanza tus metas con la metodología perfecta. Nuestro enfoque único
            y probado te guiará hacia el éxito, brindándote las herramientas y
            estrategias necesarias para destacar en tu camino. Descubre el poder
            de la excelencia con nuestra metodología que marca la diferencia.
          </h2>
        </div>
        <div class="bento-item-diagnostico">
          <h1>Diagnóstico</h1>
          <h2>
            Obtén claridad y toma decisiones informadas con nuestro servicio de
            diagnóstico. Nuestra evaluación exhaustiva proporciona insights
            precisos, permitiéndote identificar áreas de mejora y tomar medidas
            efectivas. Descubre la ruta hacia el crecimiento con nuestro
            diagnóstico experto y personalizado.
          </h2>
        </div>
        <div class="bento-item-diseño">
          <h1>Diseño</h1>
          <h2>
            Eleva tu presencia con diseño innovador. Nuestro equipo creativo da
            vida a tu visión con soluciones visualmente impactantes. Desde
            logotipos hasta experiencias digitales, transformamos ideas en
            estilos que cautivan y destacan. Descubre el poder del diseño que
            deja una impresión duradera.
          </h2>
        </div>
        <div class="bento-item-imagen">
          <img src={Greek} className="Arquitecto"></img>
        </div>
        <div class="bento-item-desarrollo">
          <h1>Desarrollo</h1>
          <h2>
            Convierte tus ideas en realidad con nuestro servicio de desarrollo
            experto. Desde la concepción hasta la implementación, creamos
            soluciones personalizadas que impulsan tu visión. Descubre el
            potencial ilimitado de tu proyecto con nuestro equipo de desarrollo
            dedicado a la excelencia.
          </h2>
        </div>
        <div class="bento-item-capacitacion">
          <h1>Capacitación</h1>
          <h2>
            Potencia tu crecimiento con nuestra capacitación especializada.
            Ofrecemos programas que impulsan habilidades clave y maximizan el
            rendimiento. Descubre el camino hacia la excelencia con nuestra
            formación diseñada para transformar tu equipo y alcanzar nuevos
            niveles de éxito.
          </h2>
        </div>
        <div class="bento-item-lanzamiento">
          <h1>Lanzamiento</h1>
          <h2>
            Impulsa tu éxito desde el principio con nuestro servicio de
            lanzamiento estratégico. Desde la planificación hasta la ejecución,
            creamos experiencias memorables que destacan tu producto o servicio.
            Descubre el arte de un lanzamiento efectivo que cautiva a tu
            audiencia y deja una impresión duradera.
          </h2>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Principal;
